import React from 'react';
import {st, classes} from './SyncListings.st.css';
import {Button, Checkbox, FloatingNotification,
    Layout, Cell, Card, Box, Input, Loader, Modal, MessageModalLayout,
    Text, RadioGroup, Dropdown, DatePicker, Tooltip} from 'wix-style-react';
import InfoCircleSmall from 'wix-ui-icons-common/InfoCircleSmall';
import StatusAlertFilledSmall from 'wix-ui-icons-common/StatusAlertFilledSmall';

import apiUtils from '../clientCommon/apiUtils';

const googleConditionsList = [
    {
        value: 'New',
        id: 'new'
    }, {
        value: 'Refurbished',
        id: 'refurbished'
    }, {
        value: 'Used',
        id: 'used'
    }
];

const googleChannelsList = [
    {
        value: 'Online',
        id: 'online'
    }, {
        value: 'Local',
        id: 'local'
    }
];

const googleAvailabilityList = [
    {
        value: 'Wix Default',
        id: '-1'
    }, {
        value: 'In Stock',
        id: 'in_stock'
    }, {
        value: 'Out of stock',
        id: 'out_of_stock'
    }, {
        value: 'Preorder',
        id: 'preorder'
    }, {
        value: 'Backorder',
        id: 'backorder'
    }
];

const googlePickupMethodsList = [
    {
        value: 'Not Supported',
        id: 'not supported'
    }, {
        value: 'Buy',
        id: 'buy'
    }, {
        value: 'Reserve',
        id: 'reserve'
    }, {
        value: 'Ship to Store',
        id: 'ship to store'
    }
];

const googlePickupSLAList = [
    {
        value: 'NA',
        id: '-1'
    }, {
        value: 'Same Day',
        id: 'same day'
    }, {
        value: 'Next Day',
        id: 'next day'
    }, {
        value: '2-Day',
        id: '2-day'
    }, {
        value: '3-Day',
        id: '3-day'
    }, {
        value: '4-Day',
        id: '4-day'
    }, {
        value: '5-Day',
        id: '5-day'
    }, {
        value: '6-Day',
        id: '6-day'
    }, {
        value: 'Multi Week',
        id: 'multi-week'
    }
];

class SyncListings extends React.Component {
    initialState = {
        productsList: [],
        showEmptyResult: true,
        loading: true,
        totalExportProds: 0,
        selectedProdsRadioOption: 'collections',
        wixCollectionsList: [],
        selectedCollection: -1,
        prodTitleRadioOption: 'title',
        prodDescRadioOption: 'desc',
        prodVarsRadioOption: 'first',
        varInTitleRadioOption: 'yes',
        prodInventoryRadioOption: 'wix',
        prodInventoryPolicyRadioOption: 'follow',
        submitLoading: false,
        selectedAvailability: '-1',
        selectedAvailabilityDate: '',
        googleShippingSettingsList: [{}],
        selectedChannel: 'online',
        storeCurrency: '',
        wixLocations: [],
        selectedLocation: '',
        submitModalOpen: false,
        submitErrorMessage: '',
        localShopStoreCode: '',
        selectedLocalShopPickupMethod: 'not supported',
        selectedLocalShopPickupSLA: 'NA',
        skuToGtin: false,
        skuToMpn: false,
        selectedCondition: 'new',
        selectedSizeWixOption: '-1',
        selectedGenderWixOption: '-1',
        wixOptionsPlaceHolder: 'Loading options details...',
        wixOptions: []
    };

    state = {
        productsList: [],
        showEmptyResult: true,
        loading: true,
        totalExportProds: 0,
        selectedProdsRadioOption: 'collections',
        wixCollectionsList: [],
        selectedCollection: -1,
        prodTitleRadioOption: 'title',
        prodDescRadioOption: 'desc',
        prodVarsRadioOption: 'first',
        varInTitleRadioOption: 'yes',
        prodInventoryRadioOption: 'wix',
        prodInventoryPolicyRadioOption: 'follow',
        submitLoading: false,
        selectedAvailability: '-1',
        selectedAvailabilityDate: '',
        googleShippingSettingsList: [{}],
        selectedChannel: 'online',
        storeCurrency: '',
        wixLocations: [],
        selectedLocation: '',
        submitModalOpen: false,
        submitErrorModalOpen: false,
        includeHiddenProducts: false,
        loadingHiddenProductsCount: false,
        collectionVisibleProdsCount: 0,
        emptyCollections: false,
        localShopStoreCode: '',
        selectedLocalShopPickupMethod: 'not supported',
        selectedLocalShopPickupSLA: 'NA',
        skuToGtin: false,
        skuToMpn: false,
        selectedCondition: 'new',
        selectedSizeWixOption: '-1',
        selectedGenderWixOption: '-1',
        wixOptionsPlaceHolder: 'Loading options details...',
        wixOptions: []
    };

    componentDidMount() {
        this.getCollections();
        this.getLocales();
        this.getWixOptions();
        this.getGoogleShippingSettings();
    }

    render() {
        return <Layout alignItems={'center'}>
            { this.state.loading ?
                <Cell span={12}>
                    <Layout alignItems={'center'}>
                        <Cell span={4} />
                        <Cell span={4}>
                            <Loader size="medium" statusMessage="Loading, please wait..." text="Loading, please wait..." status="loading" />
                        </Cell>
                        <Cell span={4} />
                    </Layout>
                </Cell> :
                <Cell>
                    <Cell span={12}>
                        <FloatingNotification type="destructive"
                        text={<div>Google may suspend your Merchant Center Account if you violate Google policies and rules. <br/>It may take several days for Google to approve your products. <a target={'_blank'} href="https://support.google.com/merchants/answer/2948694?hl=en">Learn more</a></div>}
                        fullWidth showCloseButton={false} />
                    </Cell>
                    <Cell span={12}>
                        <Card>
                            <Card.Header title="Wix Settings" subtitle={<Text>Click here to view <a href='https://www.shopupz.com/copy-of-export-listings-1#wixlistingattributes' target='_blank'>all the listing attributes</a> that we export to Google feed. View our <a href='https://www.shopupz.com/copy-of-export-listings-1' target='_blank'>video tutorial</a></Text>}></Card.Header>
                            <Card.Divider />
                            <Card.Content>
                                <Box direction="vertical" gap="4">
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                <Box>
                                                    Select collection
                                                    <Tooltip  content="Your Wix store collections (Store Products -> Collections)">
                                                        <InfoCircleSmall style={{'verticalAlign':'bottom', 'marginLeft': '1pt'}}/>
                                                    </Tooltip>
                                                </Box>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            {!this.state.emptyCollections ?
                                                <Dropdown className={classes.collList} placeholder="Loading collections details..." size="medium" selectedId={this.state.selectedCollection} 
                                                    options={this.state.wixCollectionsList} onSelect={this.templateSelected.bind(this)} />
                                            :
                                                <Text skin='error' weight='bold'>
                                                    No collections defined on your store, products cannot be exported
                                                </Text>
                                            }
                                        </Cell>
                                    </Layout> 
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Include hidden products<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.wix.com/en/article/wix-stores-changing-the-visibility-of-a-product'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Checkbox size="small" checked={this.state.includeHiddenProducts} onChange={this.includeHiddenCheckClicked.bind(this)}></Checkbox>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Total products
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Text size="small" secondary>
                                                {this.state.totalExportProds}
                                            </Text>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Product titles<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324415'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <RadioGroup
                                                display="horizontal"
                                                value={this.state.prodTitleRadioOption}
                                                onChange={this.selectedProdsTitleRadioChange.bind(this)}                                    >
                                                <RadioGroup.Radio value={'title'}>Product title</RadioGroup.Radio>
                                                <RadioGroup.Radio value={'seo'}>SEO optimized title</RadioGroup.Radio>
                                            </RadioGroup>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Product descriptions<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324468'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <RadioGroup
                                                display="horizontal"
                                                value={this.state.prodDescRadioOption}
                                                onChange={this.selectedProdsDescRadioChange.bind(this)}                                    >
                                                <RadioGroup.Radio value={'desc'}>Product description</RadioGroup.Radio>
                                                <RadioGroup.Radio value={'seo'}>SEO optimized description</RadioGroup.Radio>
                                            </RadioGroup>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Variant options
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Text size="small" secondary>
                                                First variant
                                            </Text>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Multilingual
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Dropdown className={classes.collList} placeholder="Loading languages details..." size="medium" 
                                                selectedId={this.state.selectedLocation} options={this.state.wixLocations} onSelect={this.locationSelected.bind(this)} />
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                <Box>
                                                    Currency
                                                    <Tooltip  content="Your Wix store currency (Settings -> Language &amp; Region)">
                                                        <InfoCircleSmall style={{'verticalAlign':'bottom', 'marginLeft': '1pt'}}/>
                                                    </Tooltip>
                                                </Box>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Text size="small" secondary>
                                                {this.state.storeCurrency}
                                            </Text>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Send SKU to Google GTIN<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324461?sjid=8269467762785468493-EU'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Checkbox size="small" checked={this.state.skuToGtin} onChange={this.skuToGtinClicked.bind(this)}></Checkbox>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Send SKU to Google MPN<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324482'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Checkbox size="small" checked={this.state.skuToMpn} onChange={this.skuToMpnClicked.bind(this)}></Checkbox>
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                <Box >
                                                    Send size to Google
                                                    <Tooltip content="Pick the relevant product option that represents your product size in WIX">
                                                        <InfoCircleSmall style={{'verticalAlign':'bottom', 'marginLeft': '1pt'}}/>
                                                    </Tooltip>
                                                </Box>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324492'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Dropdown className={classes.collList} placeholder={this.state.wixOptionsPlaceHolder} size="medium" 
                                                selectedId={this.state.selectedSizeWixOption} options={this.state.wixOptions} onSelect={this.wixOptionSelectedSelected.bind(this, "size")} />
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                <Box >
                                                    Send gender to Google
                                                    <Tooltip content="Pick the relevant product option that represents your product gender in WIX [Male, Femail, Unisex]">
                                                        <InfoCircleSmall style={{'verticalAlign':'bottom', 'marginLeft': '1pt'}}/>
                                                    </Tooltip>
                                                </Box>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324479'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Dropdown className={classes.collList} placeholder={this.state.wixOptionsPlaceHolder} size="medium" 
                                                selectedId={this.state.selectedGenderWixOption} options={this.state.wixOptions} onSelect={this.wixOptionSelectedSelected.bind(this, "gender")} />
                                        </Cell>
                                    </Layout>
                                </Box>
                            </Card.Content>
                        </Card>
                    </Cell>
                    <Cell span={12}>
                        <Card>
                            <Card.Header title="Google General Settings" subtitle="Select products or collections to sync to your Google store"></Card.Header>
                            <Card.Divider />
                            <Card.Content>
                                <Box direction="vertical" gap="4">
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Products condition<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324469'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Dropdown className={classes.collList} size="medium" selectedId={this.state.selectedCondition} options={googleConditionsList} onSelect={this.conditionSelected.bind(this)} />
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Products channels<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/7361332'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Dropdown className={classes.collList} size="medium" selectedId={this.state.selectedChannel} options={googleChannelsList} onSelect={this.channelSelected.bind(this)} />
                                        </Cell>
                                    </Layout>
                                    <Layout>
                                        <Cell span={4}>
                                            <Text size="small" weight='bold' secondary>
                                                Products availability<br/>
                                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324448'}>Learn more</a>
                                            </Text>
                                        </Cell>
                                        <Cell span={8}>
                                            <Dropdown className={classes.collList} size="medium" selectedId={this.state.selectedAvailability} options={googleAvailabilityList} onSelect={this.availabilitySelected.bind(this)} />
                                        </Cell>
                                    </Layout>
                                    { 
                                    ((this.state.selectedAvailability === 'preorder') || (this.state.selectedAvailability === 'backorder')) ?
                                        <Layout>
                                            <Cell span={4}>
                                                <Text size="small" weight='bold' secondary>
                                                    Availability date<br/>
                                                    {/* <InfoCircleSmall style={{'verticalAlign':'bottom'}}/> */}
                                                    <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6324470'}>Learn more</a>
                                                </Text>
                                            </Cell>
                                            <Cell span={8}>
                                            <DatePicker width={'100%'}
                                                status={this.state.selectedAvailabilityDate}
                                                clearButton
                                                onClear={(val) => {this.setState({selectedAvailabilityDate: ''});}}
                                                value={this.state.selectedAvailabilityDate} 
                                                onChange={(val) => {this.setState({selectedAvailabilityDate: val})}}
                                                size="small" />
                                            </Cell>
                                        </Layout> :
                                        null
                                    }
                                    {
                                        (this.state.googleShippingSettingsList.length === 0) ?
                                            <Layout>
                                            <Cell span={4}>
                                                <Text size="small" weight='bold' secondary>
                                                    Shipping settings<br/>
                                                    <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6069284'}>Learn more</a>
                                                </Text>
                                            </Cell>
                                            <Cell span={8}>
                                                <Text size="small" weight='bold' skin='error'>
                                                    No shipping settings found on your merchant account
                                                </Text>
                                                <StatusAlertFilledSmall style={{'color': 'red', 'verticalAlign':'bottom'}}></StatusAlertFilledSmall>
                                            </Cell>
                                        </Layout> :
                                        null
                                    }
                                </Box>
                            </Card.Content>
                        </Card>
                    </Cell>
                    {
                        this.state.selectedChannel !== 'online' ?
                            <Cell span={12}>
                                <Card>
                                    <Card.Header title="Google Local Shopping Settings" subtitle="Configure details related to local inventory management to sync to your Google store"></Card.Header>
                                    <Card.Divider />
                                    <Card.Content>
                                        <Box direction="vertical" gap="4">
                                            <Layout>
                                                <Cell span={4}>
                                                    <Text size="small" weight='bold' secondary>
                                                        Store code (Google My Business) *<br/>
                                                        <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/business/answer/4542487'}>Learn more</a>
                                                    </Text>
                                                </Cell>
                                                <Cell span={8}>
                                                    <Input
                                                        size="medium" 
                                                        status={(this.state.localShopStoreCode === '' && this.state.selectedChannel === 'local') ? 'error' : ''} 
                                                        statusMessage={(this.state.localShopStoreCode === '' && this.state.selectedChannel === 'local') ? 'This field is required' : ''}
                                                        tooltipPlacement="top-end"
                                                        value={this.state.localShopStoreCode} onChange={(val) => {this.setState({localShopStoreCode: val.target.value})}} />
                                                </Cell>
                                            </Layout>
                                            <Layout>
                                                <Cell span={4}>
                                                    <Text size="small" weight='bold' secondary>
                                                        Pickup method<br/>
                                                        <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/9817815#feedreq'}>Learn more</a>
                                                    </Text>
                                                </Cell>
                                                <Cell span={8}>
                                                    <Dropdown className={classes.collList} size="medium" selectedId={this.state.selectedLocalShopPickupMethod} options={googlePickupMethodsList} onSelect={this.pickupMethodSelected.bind(this)} />
                                                </Cell>
                                            </Layout>
                                            { 
                                            (this.state.selectedLocalShopPickupMethod !== 'not supported') ?
                                                <Layout>
                                                    <Cell span={4}>
                                                        <Text size="small" weight='bold' secondary>
                                                            Pickup SLA<br/>
                                                            {/* <InfoCircleSmall style={{'verticalAlign':'bottom'}}/> */}
                                                            <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/9439765'}>Learn more</a>
                                                        </Text>
                                                    </Cell>
                                                    <Cell span={8}>
                                                        <Dropdown className={classes.collList} size="medium" selectedId={this.state.selectedLocalShopPickupSLA} options={googlePickupSLAList} onSelect={this.pickupSLASelected.bind(this)} />
                                                    </Cell>
                                                </Layout> :
                                                null
                                            }
                                            {
                                                (this.state.googleShippingSettingsList.length === 0) ?
                                                    <Layout>
                                                    <Cell span={4}>
                                                        <Text size="small" weight='bold' secondary>
                                                            Shipping settings<br/>
                                                            <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/6069284'}>Learn more</a>
                                                        </Text>
                                                    </Cell>
                                                    <Cell span={8}>
                                                        <Text size="small" weight='bold' skin='error'>
                                                            No shipping settings found on your merchant account
                                                        </Text>
                                                        <StatusAlertFilledSmall style={{'color': 'red', 'verticalAlign':'bottom'}}></StatusAlertFilledSmall>
                                                    </Cell>
                                                </Layout> :
                                                null
                                            }
                                        </Box>
                                    </Card.Content>
                                </Card>
                            </Cell>
                        :
                        null
                    }
                    <Cell span={12}>
                        <Layout justifyItems={'center'} alignItems={'center'}>
                            <Cell>
                                {this.props.trialExpired ? 
                                <Tooltip disabled={false} appendTo={'window'} content="You cannot submit listings since your free trial has expired">
                                    <Button style={{marginTop: '5pt'}} disabled={((this.state.googleShippingSettingsList.length === 0) || (this.props.disabled) || (this.state.emptyCollections))} onClick={this.submitSync.bind(this)} size="medium">
                                        {(this.state.submitLoading || this.props.loading) ? <Loader size='tiny'></Loader> : 'Submit'}
                                    </Button>
                                </Tooltip> :
                                (this.state.localShopStoreCode === '' && this.state.selectedChannel === 'local') ?
                                <Tooltip disabled={false} appendTo={'window'} content="Store code field is required">
                                    <Button style={{marginTop: '5pt'}} 
                                        size="medium"
                                        disabled={true} >
                                        Submit
                                    </Button>
                                </Tooltip> :
                                <Button style={{marginTop: '5pt'}} 
                                    onClick={this.submitSync.bind(this)} size="medium"
                                    disabled={(
                                        (this.state.googleShippingSettingsList.length === 0) || 
                                        (this.props.disabled) || 
                                        (this.state.emptyCollections))} >
                                    {(this.state.submitLoading || this.props.loading) ? <Loader size='tiny'></Loader> : 'Submit'}
                                </Button>
                                }
                                <Modal isOpen={this.state.submitModalOpen} onRequestClose={this.closeSubmitModal.bind(this)}>
                                    <MessageModalLayout
                                        primaryButtonText="Submit"
                                        secondaryButtonText="Cancel"
                                        title="Export Details"
                                        primaryButtonProps={{'disabled': (this.state.collectionVisibleProdsCount === 0)}}
                                        onCloseButtonClick={this.closeSubmitModal.bind(this)}
                                        primaryButtonOnClick={this.modalApproved.bind(this)}
                                        secondaryButtonOnClick={this.closeSubmitModal.bind(this)}>
                                        <FloatingNotification prefixIcon={<InfoCircleSmall />} showCloseButton={false} type='warning' fullWidth text={<div>You cannot undo this action. Listings will be created or updated on your Google Merchant account<br/><b>Note that your Google account may be suspended if you violate Google policies and rules.  <a target={'_blank'} href="https://support.google.com/merchants/answer/2948694?hl=en">Learn more</a></b></div>} />
                                        <div style={{marginTop: '10pt'}}>
                                            <Layout gap={4}>
                                                <Cell span={4}>
                                                    <Text weight='bold'>
                                                        Total Listings to Export:
                                                    </Text>
                                                </Cell>
                                                {!this.state.includeHiddenProducts ?
                                                <Cell span={8}>
                                                    {this.state.loadingHiddenProductsCount ? 
                                                        <Loader size="tiny" status="loading" />
                                                        :
                                                        <Layout>
                                                            <Cell span={1}>
                                                                <Text weight='bold' skin={this.state.collectionVisibleProdsCount === 0 ? 'error' : 'standard'}>
                                                                    {this.state.collectionVisibleProdsCount}
                                                                </Text>
                                                            </Cell>
                                                            <Cell span={7}>
                                                                <Text>
                                                                    ({this.state.totalExportProds} total hidden and visible products)
                                                                </Text>
                                                            </Cell>
                                                            {this.state.collectionVisibleProdsCount === 0 ?
                                                            <Cell span={8}>
                                                                <Text weight='bold' skin='error'>
                                                                    No products selected for exporting
                                                                </Text>
                                                            </Cell> : null
                                                            }
                                                        </Layout>
                                                    }
                                                </Cell>
                                                :
                                                <Cell span={8}>
                                                    <Text>
                                                        {this.state.totalExportProds}
                                                    </Text>
                                                </Cell>}
                                                <Cell span={4}>
                                                    <Text weight='bold'>
                                                        Wix Collection:
                                                    </Text>
                                                </Cell>
                                                <Cell span={8}>
                                                    <Text>
                                                        {
                                                            ((this.state.selectedCollection !== -1) ? this.state.wixCollectionsList.filter(coll => coll.id === this.state.selectedCollection)[0].value : "-1")
                                                        }
                                                    </Text>
                                                </Cell>
                                            </Layout>
                                        </div>
                                    </MessageModalLayout>
                                </Modal>
                                <Modal isOpen={this.state.submitErrorModalOpen} onRequestClose={this.closeSubmitModal.bind(this)}>
                                    <MessageModalLayout
                                        title={<Text weight="bold">Export Listings</Text>}
                                        onCloseButtonClick={this.closeSubmitModal.bind(this)}
                                        content={<div style={{marginBottom: '5pt'}}>
                                            <Text>
                                                Error occured: {this.state.submitErrorMessage}
                                            </Text>
                                            </div>}
                                    />
                                </Modal>
                            </Cell>
                        </Layout>
                    </Cell>
                </Cell>
            }
        </Layout>;
    }

    reset() {
        this.setState({...this.initialState}, this.componentDidMount)
    }

    selectedProdsRadioChange(val) {
        this.setState({selectedProdsRadioOption: val});
    }

    selectedProdsTitleRadioChange(val) {
        this.setState({prodTitleRadioOption: val});
    }

    selectedProdsDescRadioChange(val) {
        this.setState({prodDescRadioOption: val});
    }

    selectedProdsVarsRadioChange(val) {
        this.setState({prodVarsRadioOption: val});
    }

    selectedVarInTitleRadioChange(val) {
        this.setState({varInTitleRadioOption: val});
    }

    selectedProdsInventoryRadioChange(val) {
        this.setState({prodInventoryRadioOption: val});
    }

    selectedProdsInventoryPolicyRadioChange(val) {
        this.setState({prodInventoryPolicyRadioOption: val});
    }

    getCollections() {
        apiUtils.getWixCollections()
        .then((data) => {
            if (data.collections.length > 0) {
                let options = data.collections.map(coll => {return {id: coll.id, value: coll.name, totalProds: coll.numberOfProducts}});
                this.setState({loading: false, wixCollectionsList: options, selectedCollection: options[0].id, totalExportProds: options[0].totalProds});
            }
            else {
                this.setState({emptyCollections: true});
            }
        });
    }

    getLocales() {
        apiUtils.getWixLocales()
        .then((data) => {
            let localeOptions = data.locales.map((locale) => {
                return {
                    id: `${locale.lang}|${locale.country}`,
                    value: `Language: ${locale.lang}, Country: ${locale.country}`
                }
            });
            this.setState({loading: false, storeCurrency: data.currency, wixLocations: localeOptions, selectedLocation: localeOptions[0].id});
        });
    }

    getWixOptions() {
        apiUtils.getProductOptions()
        .then((data) => {
            let options = data.options.filter(opt => opt.type !== "color").map((opt) => {
                return {
                    id: `${opt.value}`,
                    value: `${opt.name}`
                }
            });
            options.unshift({id: "-1", value: ""});
            this.setState({loading: false, wixOptions: options, wixOptionsPlaceHolder: ''});
        });
    }

    getGoogleShippingSettings() {
        apiUtils.getGoogleShippingSettings()
        .then((data) => {
            this.setState({loading: false, googleShippingSettingsList: data});
        });
    }

    templateSelected(val) {
        this.setState({selectedCollection: val.id, totalExportProds: val.totalProds});
    }

    submitSync() {
        //hidden products count number should be visible in the submit confirmation modal 
        this.setState({submitLoading: true, submitModalOpen: true, loadingHiddenProductsCount: !this.state.includeHiddenProducts});
        if (!this.state.includeHiddenProducts) {
            apiUtils.getWixCollectionVisibles(this.state.selectedCollection)
            .then((data) => {
                this.setState({loadingHiddenProductsCount: false, collectionVisibleProdsCount: data.count});
            });
        }
    }

    availabilitySelected(val) {
        this.setState({selectedAvailability: val.id,
            selectedAvailabilityDate: ((val.id !== 'preorder') && (val.id !== 'backorder') ? '' : this.state.selectedAvailabilityDate)});
    }

    pickupMethodSelected(val) {
        //when pickup method changes switch to the default SLA option
        this.setState({selectedLocalShopPickupMethod: val.id,
            selectedLocalShopPickupSLA: ((val.id === 'not supported') ? '-1' : 'same day')});
    }

    pickupSLASelected(val) {
        this.setState({selectedLocalShopPickupSLA: val.id});
    }

    channelSelected(val) {
        this.setState({selectedChannel: val.id});
    }

    conditionSelected(val) {
        this.setState({selectedCondition: val.id});
    }

    locationSelected(val) {
        this.setState({selectedLocation: val.id});
    }

    wixOptionSelectedSelected(option, val) {
        switch (option) {
            case 'size':
                this.setState({selectedSizeWixOption: val.id});
                break;
            case 'gender':
                this.setState({selectedGenderWixOption: val.id});
                break;
            default:
                break;
        }
    }

    modalApproved() {
        this.closeSubmitModal();
        let syncData = {
            channel: this.state.selectedChannel,
            lang: this.state.selectedLocation.split('|')[0],
            country: this.state.selectedLocation.split('|')[1],
            currency: this.state.storeCurrency,
            includeHidden: this.state.includeHiddenProducts,
            localStoreCode: this.state.localShopStoreCode,
            localPickupMethod: this.state.selectedLocalShopPickupMethod,
            localPickupSLA: this.state.selectedLocalShopPickupSLA,
            skuToGtin: this.state.skuToGtin,
            skuToMpn: this.state.skuToMpn,
            condition: this.state.selectedCondition
        };
        debugger;
        if (this.state.selectedSizeWixOption !== '-1') {
            syncData.size = this.state.selectedSizeWixOption;
        }
        if (this.state.selectedGenderWixOption !== '-1') {
            syncData.gender = this.state.selectedGenderWixOption;
        }
        if (this.state.prodTitleRadioOption === 'seo') {
            syncData.seoTitle = true;
        }
        if (this.state.prodDescRadioOption === 'seo') {
            syncData.seoDesc = true;
        }
        if (this.state.selectedProdsRadioOption !== 'all') {
            syncData.collections = [this.state.selectedCollection];
        }
        if (this.state.selectedAvailability !== '-1') {
            syncData.availability = this.state.selectedAvailability;
        }
        syncData.availabilityDate = this.state.selectedAvailabilityDate;
        apiUtils.submitSync(syncData)
            .then((data) => {
                if (data.hasOwnProperty("request")) {
                    this.props.onSubmitClick(data.request);
                    this.setState({submitLoading: false});
                }
                else {
                    this.setState({submitErrorModalOpen: true, submitErrorMessage: data.msg});
                }
            });
    }

    closeSubmitModal() {
        this.setState({submitErrorModalOpen: false, submitModalOpen: false, submitLoading: false});
    }

    includeHiddenCheckClicked() {
        this.setState({includeHiddenProducts: !this.state.includeHiddenProducts});
    }

    skuToGtinClicked() {
        this.setState({skuToGtin: !this.state.skuToGtin});
    }

    skuToMpnClicked() {
        this.setState({skuToMpn: !this.state.skuToMpn});
    }
}

export default SyncListings