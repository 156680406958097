import React from 'react';
import {st, classes} from './GoogleStoreSelector.st.css';
import {Badge, Cell, EmptyState, Layout, Loader, Modal, MessageModalLayout,
    Table, IconButton, TextButton, Tooltip} from 'wix-style-react';
import Refresh from 'wix-ui-icons-common/Refresh';
import Delete from 'wix-ui-icons-common/Delete';

import apiUtils from '../clientCommon/apiUtils';
import enums from '../clientCommon/enums';

class Connections extends React.Component {
    state = {
        channels: [{name: 'aa', state: 'connected'}],
        loading: true,
        removeChannelModalOpen: false,
        removeChannelModalDisabled: false,
        errorMsgModalOpen: false
    };

    columns = [
        { title: 'Channel', render: row => row.name },
        { title: 'State', width: '20%', render: row => {return <Badge type="outlined" skin="success">{this.getChannelState(row.state)}</Badge>} },
        // { title: '', render: () => {return <IconButton size='small'><Tooltip appendTo='window' content='Pause'><Pause /></Tooltip></IconButton>} },
        { title: '', width: '10%', render: () => {return <IconButton onClick={this.confirmRemove} size='small'><Tooltip appendTo='window' content='Remove Channel'><Delete /></Tooltip></IconButton>} },
    ];

    getChannelState(state) {
        switch (state) {
            case enums.ChannelStates.Connected: 
                return "Connected";
            default: 
                return "Default";}
    }

    componentDidMount() {
        this.confirmRemove = this.confirmRemove.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.removeChannel = this.removeChannel.bind(this);
        this.getChannels();
    }

    render() {
        return <Layout alignItems={'center'}>
            { this.state.loading ?
                <Cell span={12}>
                    <Layout alignItems={'center'}>
                        <Cell span={4} />
                        <Cell span={4}>
                            <Loader size="medium" statusMessage="Loading, please wait..." text="Loading, please wait..." status="loading" />
                        </Cell>
                        <Cell span={4} />
                    </Layout>
                </Cell> :
                <Cell>
                    <Layout alignItems={'center'}>
                        <Cell>
                            {this.state.channels.length > 0 ?
                                <Table data={this.state.channels}
                                    columns={this.columns}>
                                    <Table.Content />
                                </Table> :
                                <EmptyState theme="page" title="No channels found">
                                    {<TextButton onClick={this.reload} prefixIcon={<Refresh />}>Sign in to connect</TextButton>}
                                </EmptyState>
                            }
                        </Cell>
                    </Layout>
                </Cell>
            }
            <Modal isOpen={this.state.removeChannelModalOpen}
                onRequestClose={() => {this.closeModal('removeChannel')}} shouldCloseOnOverlayClick={!this.state.removeChannelModalDisabled}>
                    <MessageModalLayout
                        onCloseButtonClick={() => {this.closeModal('removeChannel')}}
                        primaryButtonText="Remove"
                        primaryButtonProps={{'disabled': this.state.removeChannelModalDisabled}}
                        primaryButtonOnClick={this.removeChannel}
                        secondaryButtonProps={{'disabled': this.state.removeChannelModalDisabled}}
                        title="Remove Channel"
                        secondaryButtonText="Cancel"
                        secondaryButtonOnClick={() => {this.closeModal('removeChannel')}}>
                            Are you sure you want to remove that channel?
                    </MessageModalLayout>
            </Modal>
            <Modal isOpen={this.state.errorMsgModalOpen}
                onRequestClose={() => {this.closeModal('error')}}>
                    <MessageModalLayout
                        onCloseButtonClick={() => {this.closeModal('error')}}
                        primaryButtonText="OK"
                        primaryButtonOnClick={() => {this.closeModal('error')}}
                        title="Error">
                            Something went wrong, please try again
                    </MessageModalLayout>
            </Modal>
        </Layout>
    }

    getChannels() {
        this.setState({loading: true});
        apiUtils.getChannels()
        .then((data) => {
            this.setState({loading: false, channels: data.channels});
        });
    }

    confirmRemove() {
        this.setState({removeChannelModalOpen: true});
    }

    closeModal(modal) {
        this.setState({removeChannelModalOpen: false, errorMsgModalOpen: false});
    }

    removeChannel() {
        this.setState({removeChannelModalDisabled: true});
        apiUtils.removeChannel()
        .then((data) => {
            this.setState({removeChannelModalDisabled: false});
            if (data.status === 0) {
                this.setState({removeChannelModalOpen: false});
                this.getChannels();
            }
            else {
                this.setState({errorMsgModalOpen: true});
            }
        });
    }

    reload() {
        location.reload();
    }
}

export default Connections