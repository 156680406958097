import React from 'react';
import {st, classes} from './Home.st.css';
import {Button, Cell, Image, Loader, Layout, Modal, MessageModalLayout,
    Page, PopoverMenu, FloatingNotification} from 'wix-style-react';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import Replace from 'wix-ui-icons-common/Replace';
import WixChatFilledSmall from 'wix-ui-icons-common/WixChatFilledSmall';

import Wizard from './Wizard';
import TabsMain from './TabsMain';
import GoogleStoreSelector from './GoogleStoreSelector';
import apiUtils from '../clientCommon/apiUtils';
import enums from '../clientCommon/enums';
import GoogleTokenExpired from './GoogleTokenExpired';

const TRIAL_NOT_EXPIRED = "Your FREE trial will end in %DAYS% days.";
const TRIAL_EXPIRED = "Your FREE trial has expired."

class Home extends React.Component {
    state = {
        wizardState: null,
        loading: true,//change to false when debugging
        googleStoreName: '',
        showSwitchStoreModal: false,
        selectedAccountId: '', 
        selectedMerchantId: '',
        storeModalDisabled: true,
        storeModalCancelDisabled: false,
        counter: 0,
        actionLoading: true,
        showTrialNotification: false,
        trialMessage: '',
        trialDaysRemaining: 0,
        trialNotification: 'warning',
        chargeLoading: false,
        trialExpired: false,
        generalError: false,
        generalErrorMessage: ''
    };

    componentDidMount() {
        this.getWizardState = this.getWizardState.bind(this);
        this.getGoogleStoreName = this.getGoogleStoreName.bind(this);
        this.setStore = this.setStore.bind(this);
        this.googleAccountSelected = this.googleAccountSelected.bind(this);
        this.googleMerchantSelected = this.googleMerchantSelected.bind(this);
        this.startWixCharge = this.startWixCharge.bind(this);
        this.getWizardState();
        this.getTrialDaysRemaining();
    }

    render() {
        return <div className={st(classes.mainContainer)}>
        <Page className={st(classes.mainPage)} height="100vh">
        <Page.Header className={st(classes.mainHeader, (this.wizardActive() ? classes.wizardHeader : ''))} 
            actionsBar={((this.state.wizardState !== null) && (this.state.wizardState.step === 999) && (this.state.wizardState.state !== enums.WizardStates.GoogleLogin_ExpiredRefreshToken)) ?
                <PopoverMenu
                    triggerElement={
                        <Button disabled={this.state.actionLoading} skin="inverted" suffixIcon={<ChevronDown />}>
                            {this.state.actionLoading ? <Loader size="tiny" /> : this.state.googleStoreName}
                        </Button>
                    }>
                    <PopoverMenu.MenuItem 
                        onClick={() => {this.setState({showSwitchStoreModal: true, 
                            storeModalDisabled: true,
                            storeModalCancelDisabled: false,
                            selectedAccountId: '', 
                            selectedMerchantId: ''})}}
                        text="Switch Store"
                        prefixIcon={<Replace />}
                    />
                </PopoverMenu> :
                null
            }
            title={
                // (this.state.wizardState !== null && this.state.wizardState.step !== 999) ? 
                    <Layout>
                        <Cell span={1}></Cell>
                        <Cell span={1}></Cell>
                        <Cell span={1}>
                            <Image className={classes.headerImage} style={{"backgroundColor":"transparent"}} height="50px" width="50px" src="https://static.wixstatic.com/media/432bcd_2b30846acba64965b38dbc9e47e95528~mv2.png" alt="shopUpz Inventory Management"></Image>
                        </Cell>
                        <Cell span={9}>
                            shopUpz Inventory Management
                        </Cell>
                    </Layout>
                    // :
                    // <Layout>
                    //     <Cell>
                    //         shopUpz Inventory Management
                    //     </Cell>
                    // </Layout>
            }
            subtitle={( this.wizardActive() ? "Let's connect between your Wix store and Google Shopping" : "")} />
        <Page.Content>
            { this.state.loading ?
                <Loader size="medium" statusMessage="Loading, please wait..." text="Loading, please wait..." status="loading" /> :
                this.state.generalError ?
                    <FloatingNotification fullWidth showCloseButton={false} type={this.state.trialNotification} 
                    text={<Layout alignItems={'center'}>
                            <Cell span={12}>
                                <b>{this.state.generalErrorMessage}</b>
                            </Cell>
                        </Layout>} />
                :
                ((this.state.wizardState.step !== 999) ? <Wizard onRefresh={this.getWizardState.bind(this)} wizardState={this.state.wizardState} /> : 
                <Layout alignItems={'center'}>
                    {this.state.showTrialNotification ?
                        <Cell span={12}>
                            <FloatingNotification fullWidth showCloseButton={false} type={this.state.trialNotification} 
                                text={<Layout alignItems={'center'}>
                                    <Cell span={12}>
                                        <b>{this.state.trialMessage} <a onClick={this.startWixCharge} style={{color: 'blue', textDecoration: 'underline'}}>upgrade today</a> to get full access</b>
                                        <span style={{verticalAlign: 'bottom', marginLeft: '10pt', visibility: (this.state.chargeLoading ? 'visible' : 'hidden')}}><Loader size="tiny" status="loading" /></span>
                                    </Cell>
                                </Layout>} />
                        </Cell> : null}
                    <Cell span={12}>
                        <FloatingNotification fullWidth showCloseButton={false} type='success' 
                            text={<Layout alignItems={'center'}>
                                <Cell span={12}>
                                    <WixChatFilledSmall style={{'verticalAlign': 'bottom','marginRight': '5pt'}} />
                                    For support email us at <a target='_blank' style={{'color': 'blue'}} href='mailto:shirly@shopupz.com'>shirly@shopupz.com</a> or <a target='_blank' style={{'color': 'blue'}} href='https://m.me/100356568366876?ref=vvY7x0ADnYn0y'>FB Messenger</a>
                                </Cell>
                            </Layout>} />
                    </Cell>
                    <Cell>
                        {(this.state.wizardState.state === enums.WizardStates.GoogleLogin_ExpiredRefreshToken) ? <GoogleTokenExpired /> : <TabsMain trialExpired={this.state.trialExpired} state={this.state.counter} />}
                    </Cell>
                </Layout>)
            }
            {
                this.state.showSwitchStoreModal ?
                <div>
                    <Modal isOpen={this.state.showSwitchStoreModal} 
                        onRequestClose={() => {this.setState({showSwitchStoreModal: false})}}
                        shouldCloseOnOverlayClick={true}>
                        <MessageModalLayout
                            onCloseButtonClick={() => {this.setState({showSwitchStoreModal: false})}}
                            primaryButtonText="Save"
                            primaryButtonOnClick={this.setStore}
                            primaryButtonProps={{'disabled': this.state.storeModalDisabled}}
                            title="Switch Store"
                            secondaryButtonText="Cancel"
                            secondaryButtonProps={{'disabled': this.state.storeModalCancelDisabled}}
                            secondaryButtonOnClick={() => {this.setState({showSwitchStoreModal: false})}}>
                            <GoogleStoreSelector accountSelected={this.googleAccountSelected} merchantSelected={this.googleMerchantSelected} />
                        </MessageModalLayout>
                    </Modal>
                </div> : 
                null
            }
        </Page.Content>
        </Page>
    </div>;
    }

    wizardActive() {
        return (this.state.wizardState !== null) && (this.state.wizardState.step !== 999);
    }

    getWizardState() {
        apiUtils.getWizardState()
        .then((data) => {
            if (data.hasOwnProperty("error")) {
                this.setState({loading: false, generalError: true, generalErrorMessage: data.error});
            }
            else {
                this.setState({loading: false, wizardState: data.wizard});
                if (data.wizard.step === 999) {
                    this.getGoogleStoreName();
                }
            }
        });
    }

    getGoogleStoreName() {
        apiUtils.getGoogleMarchantAccounts()
        .then((data) => {
            let selectedAccount = data.accounts.filter(acc => acc.stores.filter(store => store.selected).length > 0)[0];
            let selectedStore = ((selectedAccount !== undefined) ? selectedAccount.stores.filter(store => store.selected)[0] : '');
            this.setState({googleStoreName: selectedStore.name, actionLoading: false});
        });
    }

    googleAccountSelected(val) {
        if (this.state.selectedAccountId !== '') {
            this.setState({storeModalDisabled: false});
        }
        this.setState({selectedAccountId: val});
    }

    googleMerchantSelected(val) {
        if (this.state.selectedMerchantId !== '') {
            this.setState({storeModalDisabled: false});
        }
        this.setState({selectedMerchantId: val});
    }

    setStore () {
        console.log(this.state.selectedAccountId);
        console.log(this.state.selectedMerchantId);
        this.setState({storeModalDisabled: true, storeModalCancelDisabled: true});
        apiUtils.setGoogleMarchant(this.state.selectedAccountId, this.state.selectedMerchantId)
        .then((data) => {
            this.setState({counter: ++this.state.counter, showSwitchStoreModal: false});
            this.getGoogleStoreName();
        })
    }

    getTrialDaysRemaining() {
        apiUtils.getTrialDays()
        .then((data) => {
            if (data.remainingDays < 0) {
                this.setState({trialExpired: true, trialMessage: TRIAL_EXPIRED, trialNotification: 'destructive', showTrialNotification: (data.remainingDays !== null)});
            }
            else if (data.remainingDays <= 5) {
                this.setState({trialExpired: false, trialMessage: TRIAL_NOT_EXPIRED.replace('%DAYS%', data.remainingDays), trialNotification: 'destructive', showTrialNotification: (data.remainingDays !== null)});
            }
            else {
                this.setState({trialExpired: false, trialMessage: TRIAL_NOT_EXPIRED.replace('%DAYS%', data.remainingDays), trialNotification: 'warning', showTrialNotification: (data.remainingDays !== null)});
            }
        })
    }

    startWixCharge() {
        this.setState({chargeLoading: true});
        apiUtils.getWixChargeUrl()
        .then((data) => {
            window.parent.location.href = data.url;
        });
    }
}

export default Home
