import React from 'react';
import ReactDOM from 'react-dom';
import Main from './views/Main/Main';
import Home from './components/Home';

import './base.st.css';

ReactDOM.render(
  <Home />,
  document.getElementById('app')
);