import React from 'react';
import {st, classes} from './Wizard.st.css';
import {Layout, Cell, Button,
    EmptyState, Loader, Stepper, 
    Box, FloatingNotification, Text,
    Dropdown, Image, FormField} from 'wix-style-react';
import StatusComplete from 'wix-ui-icons-common/StatusComplete';
import ExternalLinkSmall from 'wix-ui-icons-common/ExternalLinkSmall';
import GoogleLogo from '../svg/google.svg';
import google_auth from '../static/google_authenticate.png';
import google_auth2 from '../static/google_authenticate-2.png';

import apiUtils from '../clientCommon/apiUtils';
import enums from '../clientCommon/enums';

class Wizard extends React.Component {
    state = {
        currentStep: 0,
        googleLoginButtonLoading: false,
        googleLoginButtonDisabled: false,
        googleUIButtonLoading: true,
        googleDetailsError: false,
        googleDetailsErrorMsg: '',
        googleDetailsLoaded: false,
        googleUserName: '',
        googleUserEmail: '',
        googleLoginState: null,
        googleAccountsData: [],
        googleMerchantsList: [],
        selectedMerchantId: '',
        googleMerchantsAccountsList: [],
        selectedAccountId: '',
        nextStepLoading: false,
        showNextStep: true,
        disableNextStep: false,
        googleMerchantAccountMissing: false
    };

    componentDidMount() {
        this.handleWizardState = this.handleWizardState.bind(this);
        this.startGoogleAuth = this.startGoogleAuth.bind(this);
        this.merchantSelected = this.merchantSelected.bind(this);
        this.merchantAccountSelected = this.merchantAccountSelected.bind(this);
        this.handleNextStepClick = this.handleNextStepClick.bind(this);
        this.handleWizardState();
    }

    renderSteps (step) {
        switch (step) {
            case 0:
                //wizard step 1
                return <Cell>
                    <Layout gap={5} cols={1} alignItems="center">
                        <EmptyState
                            image={"https://static.wixstatic.com/media/432bcd_2b30846acba64965b38dbc9e47e95528~mv2.png"}
                            theme="page">
                            <Text size='medium' weight='bold'>Welcome to shopUpz</Text>
                        </EmptyState>
                        <Button onClick={() => {this.setState({currentStep: 1});}}
                        title="Continue">Continue</Button>
                    </Layout>
                </Cell>
            //wizard step 2
            case 1:
                return <Cell>
                    { this.prepareGoogleLoginStep() }
                </Cell>
            //wizard step 3
            case 2:
                return <Cell>
                    { this.prepareGoogleAccountSelectStep() }
                </Cell>
            default:
                return <Layout>
                        <Cell>
                            <FloatingNotification width='100%' type='success' showCloseButton={false} prefixIcon={<StatusComplete />} 
                              text={<Text size='medium' weight='bold'>Congratulations! You have connected succefully to your Google Shopping account.</Text>}></FloatingNotification>
                        </Cell>
                    </Layout>
        }
    }

    render() {
        return <div>
            <Layout gap={20} justifyItems={'center'}>
                <Cell>
                    <Stepper activeStep={this.state.currentStep} fit="stretched"
                        onClick={stepIndex => this.setState({currentStep: stepIndex})}
                        steps={[
                        {
                            text: 'Welcome', type: ((this.state.currentStep === 0) ? 'normal' : 'disabled')
                        },
                        {
                            text: 'Google Shopping Setup - Login', type: ((this.state.currentStep <= 1) ? 'normal' : 'disabled')
                        },
                        {
                            text: 'Google Shopping Setup - Account Selection', type: ((this.state.currentStep === 2) ? 'normal' : 'disabled')
                        },
                        {
                            text: 'Done', type: ((this.state.currentStep === 3) ? 'normal' : 'disabled')
                        }
                        ]}
                    />
                </Cell>
                <Cell>
                    <Box width="100%" align="center" className={classes.suEmptyState}>
                        { this.renderSteps(this.state.currentStep) }
                    </Box>
                </Cell>
                {this.state.showNextStep ? 
                    <Cell span={12}>
                        <Button disabled={this.state.disableNextStep} onClick={this.handleNextStepClick}>
                            { !this.state.nextStepLoading ? ((this.state.currentStep === 3) ? "Done" : "Next Step") :  <Loader size="tiny" /> }
                        </Button>
                    </Cell> :
                    <Cell></Cell>
                }
            </Layout>
        </div>;
    }

    handleNextStepClick() {
        if (this.state.currentStep === 2) {
            this.setState({nextStepLoading: true});
            apiUtils.setGoogleMarchant(this.state.selectedAccountId, this.state.selectedMerchantId)
            .then((data) => {
                this.setState({currentStep: ++this.state.currentStep, nextStepLoading: false});
            })
        }
        else if (this.state.currentStep === 3) {
            this.setState({nextStepLoading: true, disableNextStep: true});
            this.props.onRefresh();
        }
    }

    handleWizardState() {
        if (this.props.wizardState.step !== 999) {
            this.setState({
                currentStep: this.props.wizardState.step});
        }
        if (this.props.wizardState.step === 0) {
            this.setState({
                showNextStep: false,
                googleLoginState: <Text size='medium' weight='bold'>Connect your Google Merchant account</Text>});
        }
        else if (this.props.wizardState.step === 1) {
            if (this.props.wizardState.state === 0) {
                this.setState({
                    googleLoginState: <Text size='medium' weight='bold'>Connect your Google Merchant account</Text>
                });
            }
            else if ((this.props.wizardState.state === enums.WizardStates.GoogleLogin_AccessDenied) ||
                (this.props.wizardState.state === enums.WizardStates.GoogleLogin_LoginFailed)) {
                this.setState({
                    googleLoginState: <FloatingNotification showCloseButton={false} type='destructive' text='There was a probelm with Google login, try again in order to proceed' />,
                    showNextStep: false
                });
            }
            else if (this.props.wizardState.state === enums.WizardStates.GoogleLogin_MissingScopes) {
                this.setState({
                    showNextStep: false
                });
            }
        }
        else if (this.props.wizardState.step === 2) {
            apiUtils.getGoogleUser()
            .then((data) => {
                if (data.hasOwnProperty("error")) {
                    this.setState({googleDetailsLoaded: true, googleDetailsError: true, googleDetailsErrorMsg: data.error, showNextStep: false});
                }
                else {
                    this.setState({googleDetailsLoaded: true, googleUserName: data.name, googleUserEmail: data.email, showNextStep: true, disableNextStep: true});
                }
            });
            apiUtils.getGoogleMarchantAccounts()
            .then((data) => {
                if (data.hasOwnProperty("error") && (data.code === 403)) {
                    this.setState({googleDetailsLoaded: true, googleDetailsError: true, googleDetailsErrorMsg: `${data.code} ${data.error}`});
                }
                else {
                    this.setState({googleAccountsData: data, disableNextStep: false});
                    if (data.accounts.length === 0) {
                        this.setState({googleDetailsLoaded: true, googleDetailsError: true, googleMerchantAccountMissing: true});
                    }
                    else if (data.accounts.length === 1) {
                        //in case of only one account, we will allow choosing only sub accounts
                        let stores = data.accounts[0].stores.map(merch => {return {id: merch.merchantId, value: merch.name}});
                        this.setState({googleMerchantsList: stores,
                            selectedAccountId: data.accounts[0].account,
                            selectedMerchantId: data.accounts[0].stores[0].merchantId});
                    }
                    else {
                        //in case of multiple accounts, we will allow choosing accounts and their sub accounts
                        //prepare data source for accounts drop dowm
                        let accounts = data.accounts.map((acc) => {return {id: acc.account, value: acc.name}});
                        //prepare data source for account stores drop dowm (default list will be of the first account)
                        let stores = data.accounts[0].stores.map(merch => {return {id: merch.merchantId, value: merch.name}});
                        this.setState({googleMerchantsAccountsList: accounts,
                            googleMerchantsList: stores,
                            selectedAccountId: accounts[0].id,
                            selectedMerchantId: stores[0].id});
                    }
                }
            });
        }
    }

    startGoogleAuth() {
        this.setState({googleLoginButtonLoading: true, googleLoginButtonDisabled: true, showNextStep: false});
        apiUtils.getGoogleAuthUrl()
        .then((data) => {
            window.parent.location.href = data.url;
        });
    }

    prepareGoogleLoginStep() {
        setTimeout(this.setupGoogleSigninButton, 200);
        setTimeout(() => {
            gapi.signin2.render('google-signin', {
                'scope': 'openid profile email',
                'longtitle': true,
                'theme': 'dark'
            });
        }, 2000);
        let googleUITO = setTimeout(() => {
            this.setState({googleUIButtonLoading: false});
        }, 3000);
        //avoid endless loop due to setting state inside settimeout
        // once google ui is loaded, clear the timeout setting state
        // https://stackoverflow.com/questions/71649336/why-change-state-inside-settimeout-make-an-infinity-loop
        if (!this.state.googleUIButtonLoading) {
            clearTimeout(googleUITO);
        }
        return <Layout cols={1} justifyItems={'center'} alignItems="center">
            <Cell>
                {(this.props.wizardState.state !== enums.WizardStates.GoogleLogin_MissingScopes) ?
                    <Text size='medium' weight='bold'>Please provide access to your Google account by selecting all options as described in the image below</Text>
                    : <Text size='medium' weight='bold'>We are still missing some permissions, please sign in again to proceed</Text>
                }
            </Cell>
            <Cell>
                {(this.props.wizardState.state !== enums.WizardStates.GoogleLogin_MissingScopes) ?
                    <Image src={google_auth}></Image>
                    : <Image src={google_auth2}></Image>
                }
            </Cell>
            {/* <Cell>
                <EmptyState
                    image={GoogleLogo}
                    subtitle=""
                    theme="page"
                    title="">
                    { this.state.googleLoginState }
                </EmptyState>
            </Cell> */}
            <Cell>
                {this.state.googleUIButtonLoading ?
                    <Button disabled={true}>
                        <Loader size="tiny" />  
                    </Button>
                    :
                    <div onClickCapture={(e) => {e.preventDefault();e.stopPropagation();this.startGoogleAuth();}}
                        style={{opacity: (this.state.googleLoginButtonDisabled ? 0.5 : 1)}}>
                        <div id="google-signin"></div>
                    </div>
                }
                {/* <Button onClick={this.startGoogleAuth} disabled={this.state.googleLoginButtonDisabled}
                  title="Connect to Google">
                    { !this.state.googleLoginButtonLoading ? "Connect to Google" :  <Loader size="tiny" /> }  
                </Button> */}
            </Cell>
        </Layout>;
    }

    prepareGoogleAccountSelectStep() {
        return <Layout gap={10} cols={1} alignItems="center">
            <EmptyState
                image={GoogleLogo}
                subtitle=""
                theme="page"
                title="">
                { this.state.googleLoginState }
            </EmptyState>
            {this.state.googleDetailsLoaded
                ? !this.state.googleDetailsError ? 
                    <Box gap={3} direction='vertical'>
                        <Text>Hi {this.state.googleUserName} ({this.state.googleUserEmail})</Text>
                        {(this.state.googleMerchantsAccountsList.length > 1) ?
                            <FormField label="Google Account">
                                <Dropdown className={classes.merchantsList} size="medium" options={this.state.googleMerchantsAccountsList} onSelect={this.merchantAccountSelected} selectedId={this.state.selectedAccountId} />
                            </FormField>
                            : null
                        }
                        <FormField label={(this.state.googleMerchantsAccountsList.length > 1) ? "Sub Accounts" : "Google Account"}>
                            <Dropdown placeholder="Loading details..." className={classes.merchantsList} size="medium" options={this.state.googleMerchantsList} onSelect={this.merchantSelected} selectedId={this.state.selectedMerchantId} />
                        </FormField>
                    </Box> :
                    this.state.googleMerchantAccountMissing ?
                    <Box direction="vertical" align="center" gap="3">
                        <Box width="100%">
                            <Text size='medium'>Google Merchant Center account missing</Text>
                        </Box>
                        <Box width="100%">
                            <Text size='small'>
                                Please sign up before starting using this app<br/>
                                <a style={{'color':'blue', 'marginLeft': '1pt', 'fontSize': '12px', 'textDecoration': 'underline'}} target={'_blank'} href={'https://support.google.com/merchants/answer/188924'}>Learn more<ExternalLinkSmall style={{'verticalAlign':'bottom'}}/></a>
                            </Text>
                        </Box>
                    </Box> :
                    <Box direction="vertical" align="center" gap="3">
                        <Box width="100%">
                            <Text>There was a probelm getting your details, please login again (error: {this.state.googleDetailsErrorMsg})</Text>
                        </Box>
                        <Box width="50%" align="center">
                            <Button fullWidth={false} onClick={this.startGoogleAuth} disabled={this.state.googleLoginButtonDisabled}
                                title="Connect to Google">
                                { !this.state.googleLoginButtonLoading ? "Connect to Google" :  <Loader size="tiny" /> }  
                            </Button>
                        </Box>
                    </Box>
                : <Text><Loader size="tiny" /> Loading your account data</Text>}
        </Layout>;
    }

    generateGoogleAccountDropDowns(accountData) {
        if (accountData.accounts.length === 1) {
            return <Dropdown className={classes.merchantsList} size="medium" options={this.state.googleMerchantsList} onSelect={this.merchantSelected} />
        }
    }

    merchantSelected(val) {
        this.setState({disableNextStep: false, selectedMerchantId: val.id.toString()});
    }

    merchantAccountSelected(val) {
        let account = this.state.googleAccountsData.accounts.filter(acc => acc.account === val.id)[0];
        let stores = account.stores.map(merch => {return {id: merch.merchantId, value: merch.name}});
        this.setState({googleMerchantsList: stores,
            selectedMerchantId: stores[0].id,disableNextStep: false, 
            selectedAccountId: val.id.toString()});
    }

    //prepare for rendering Google sign in button
    //  https://developers.google.com/identity/sign-in/web/build-button
    setupGoogleSigninButton() {
        let meta = document.createElement("meta");
        meta.name = "google-signin-client_id";
        meta.content = process.env.GOOGLE_CLIENT_ID;
        document.getElementsByTagName("head")[0].appendChild(meta);

        let scr = document.createElement("script");
        scr.src = "https://apis.google.com/js/platform.js";
        document.getElementsByTagName("head")[0].appendChild(scr);
    }
}

export default Wizard
