import React from 'react';
import {st, classes} from './SyncStatus.st.css';
import {EmptyState,
    TextButton,
    Layout, Cell, FloatingNotification,
    Loader, Table, TableActionCell, TableToolbar,
    Box, Badge, DatePicker, Dropdown, IconButton, Image, Search, Tooltip} from 'wix-style-react';
import Add from 'wix-ui-icons-common/Add';
import ExternalLinkSmall from 'wix-ui-icons-common/ExternalLinkSmall';
import InfoCircle from 'wix-ui-icons-common/InfoCircle';
import InfoCircleSmall from 'wix-ui-icons-common/InfoCircleSmall';
import Refresh from 'wix-ui-icons-common/Refresh';
import StatusAlert from 'wix-ui-icons-common/StatusAlert';
import StatusWarning from 'wix-ui-icons-common/StatusWarning';
import ChevronRight from 'wix-ui-icons-common/ChevronRight';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';

import apiUtils from '../clientCommon/apiUtils';

class SyncStatus extends React.Component {
    doneTypingInterval = 800;

    iniitialState = {
        productsList: [],
        showEmptyResult: true,
        loading: true,
        stateSearch: '',
        textSearch: '',
        startedTyping: false,
        searchFromDate: null,
        searchFromStatus: '',
        searchToDate: null,
        totalStates: 0,
        totalActive: 0,
        totalPending: 0,
        totalDisapproved: 0,
        contentLoading: false,
        typingTimer: -1
    };

    state = {
        productsList: [],
        showEmptyResult: true,
        loading: true,
        stateSearch: '',
        textSearch: '',
        searchFromDate: null,
        searchFromStatus: '',
        searchToDate: null,
        totalStates: 0,
        totalActive: 0,
        totalPending: 0,
        totalDisapproved: 0,
        contentLoading: false,
        typingTimer: -1,
        refreshDisabled: true,
        refreshLoading: false
    };

    columns = [
        { render: row => row.expandable ? (row.expanded ? <ChevronDown /> : <ChevronRight />) : null, width: '2%', align: 'start' },
        { render: row => <Image src={row.details.imageUrl} />, width: '6%' },
        { title: 'Wix Listing', render: row => <a style={{'color':'blue'}} target={'_blank'} href={row.details.sourceUrl}>{row.details.title}</a>, width: '20%' },
        { title: 'Google Listing', render: row => (row.details.hasOwnProperty("targetUrl") ? <a style={{'color':'blue'}} target={'_blank'} href={row.details.targetUrl}>Edit<ExternalLinkSmall style={{'verticalAlign':'bottom'}}/></a> : null), width: '15%' },
        { title: 'State', render: row => this.generateStateCell(row.state), width: '15%' },
        { title: 'Issues', render: row => this.generateIssuesCell(row), width: '10%' },
        { title: 'Updated', render: row => new Date(row.details.lastUpdateDate).toLocaleString(), width: '17%' },
        {
            width: '23%',
            title: '',
            render: row =>
              row.expandable ? (
                <TableActionCell
                  primaryAction={{
                    text: row.expanded ? 'View Details' : 'Close',
                    skin: 'inverted',
                    onClick: row.expanded = !row.expanded,
                  }}
                />
              ) : (
                ''
              ),
          },
    ];

    issuesTableColumns = [
        { title: '', width: '6%', render: (row) => this.generateActionStateCell(row.state)},
        { title: 'Name', render: row => row.name, width: '20%' },
        { title: 'Fix suggestions', render: (row) => { return <div>{row.desc}<br/>{row.learnMore !== undefined ?<a target={'_blank'} href={row.learnMore}>Learn more</a>:null}</div>; }, width: '74%' },
    ]

    componentDidMount() {
        this.newProductClicked = this.newProductClicked.bind(this);
        this.getFilteredData = this.getFilteredData.bind(this);
        this.mainToolbar = this.mainToolbar.bind(this);
        this.doneTyping = this.doneTyping.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getProductsList();
    }

    mainToolbar() {
        return (
        <TableToolbar>
            <TableToolbar.ItemGroup position="start">
                <TableToolbar.Item>
                    <TableToolbar.Label>
                        Filter by
                        <Box width="175">
                            <Dropdown
                                className={classes.collList}
                                options={[
                                    { id: '', value: `All States (${this.state.totalStates})` },
                                    { id: 'Disapproved', value: `Disapproved (${this.state.totalDisapproved})` },
                                    { id: 'Active', value: `Active (${this.state.totalActive})` },
                                    { id: 'Pending', value: `Pending (${this.state.totalPending})` },
                                ]}
                                selectedId={this.state.stateSearch}
                                onSelect={({ id }) => { 
                                    this.setState({stateSearch: id, contentLoading: true}, this.getProductsList);
                                }}
                                popoverProps={{ appendTo: 'window' }}
                            />
                        </Box>
                    </TableToolbar.Label>
                </TableToolbar.Item>
                <TableToolbar.Item>
                    <Box width="160">
                        <DatePicker width={'100%'}
                            status={this.state.searchFromStatus}
                            statusMessage="From date cannot be later than To date"
                            clearButton
                            onClear={(val) => {this.setState({searchFromDate: null, searchFromStatus: ''});}}
                            value={this.state.searchFromDate} 
                            onChange={(val) => {this.setState({searchFromDate: val,
                                searchFromStatus: ((new Date(val) > new Date(this.state.searchToDate)) ? 'error' : ''),
                                contentLoading: true}, this.getProductsList)
                            }}
                            placeholderText="From Date" size="medium" />
                    </Box>
                </TableToolbar.Item>
                <TableToolbar.Item>
                    <Box width="160">
                        <DatePicker width={'100%'}
                            clearButton
                            onClear={(val) => {this.setState({searchToDate: null, searchFromStatus: ''});}}
                            value={this.state.searchToDate} 
                            filterDate={date => (this.state.searchFromDate === null) || (date >= new Date(this.state.searchFromDate))} 
                            onChange={(val) => {
                                this.setState({searchToDate: val,
                                    searchFromStatus: ((new Date(val) < new Date(this.state.searchFromDate)) ? 'error' : ''),
                                    contentLoading: true}, this.getProductsList);
                            }}
                            placeholderText="To Date" size="medium" />
                    </Box>
                </TableToolbar.Item>
                <TableToolbar.Item>
                    <Box width="160">
                        {
                            this.state.refreshLoading ? 
                                <Loader size="tiny" statusMessage="Loading, please wait..." status="loading" />
                            :
                            <IconButton onClick={this.refresh} size="small" disabled={this.state.refreshDisabled}>
                                <Tooltip appendTo="window" content="Refresh" size="small">
                                    <Refresh />
                                </Tooltip>
                            </IconButton>
                        }
                    </Box>
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
            <TableToolbar.ItemGroup position="end">
                <TableToolbar.Item>
                    <Box width="200">
                    <Search
                        size='small'
                        placeholder='Search SKU or Title'
                        value={this.state.textSearch}
                        onKeyUp={(e) => {
                            if ((e.code !== 'ArrowLeft') && (e.code !== 'ArrowRight') && (e.code !== 'ArrowUp') && (e.code !== 'ArrowDown')) {
                                clearTimeout(this.state.typingTimer);
                                this.setState({typingTimer: setTimeout(this.doneTyping, this.doneTypingInterval)});
                            }
                        }}
                        onKeyDown={(e) => {clearTimeout(this.state.typingTimer);}}
                        onChange={(event) => { this.setState({textSearch: event.target.value, startedTyping: true}); }}
                        onClear={(event) => { this.setState({textSearch: '', startedTyping: true}, this.doneTyping); }}
                    />
                    </Box>
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
        </TableToolbar>
        );
    };

    render() {
        return <Layout>
            <Cell span={12}>
                { this.state.loading ?
                    <Layout>
                        <Cell span={4} />
                        <Cell span={4}>
                            <Loader size="medium" statusMessage="Loading, please wait..." text="Loading, please wait..." status="loading" />
                        </Cell>
                        <Cell span={4} />
                    </Layout> :
                    <Cell span={12}>
                        <Table showSelection rowDetails={row => this.expandRow(row)} data={this.getFilteredData()} columns={this.columns}>
                                <Table.ToolbarContainer>{selectionContext => 
                                        this.mainToolbar()
                                    }
                                </Table.ToolbarContainer>
                                <Table.SubToolbar>
                                    { !this.state.showEmptyResult ?
                                    <Layout>
                                        <Cell span={12}>
                                            <FloatingNotification fullWidth showCloseButton={false} type='standard'
                                                text={<Layout alignItems={'center'}>
                                                    <Cell span={12}>
                                                    <InfoCircleSmall style={{'verticalAlign':'bottom'}} /> Sample data. For full information go to your Google Merchant Center account → Products section
                                                    </Cell>
                                                </Layout>} />
                                        </Cell>
                                    </Layout> : null }
                                </Table.SubToolbar>
                                {
                                    this.state.contentLoading ?
                                        <Box direction='vertical'>
                                            <Box>
                                                <FloatingNotification
                                                    type="preview" fullWidth={true} showCloseButton={false}
                                                    text="Loading, please wait..." prefixIcon={<InfoCircle />} />
                                            </Box>
                                            <Box visibility='hidden'>
                                                <Table.Content/>
                                            </Box>
                                        </Box>
                                    :
                                    this.state.showEmptyResult ?
                                    <Box direction='vertical'>
                                        <EmptyState theme="page" title="No data found">
                                            {<TextButton onClick={this.newProductClicked} prefixIcon={<Add />}>Export New Product</TextButton>}
                                        </EmptyState>
                                        <Box visibility='hidden'>
                                            <Table.Content/>
                                        </Box>
                                    </Box>
                                    :
                                    <Table.Content/>
                                }
                        </Table>
                    </Cell>
                }
            </Cell>
        </Layout>;
    }

    //pressing the refresh button
    refresh() {
        this.setState({refreshDisabled: true, contentLoading: true, refreshLoading: true});
        this.getProductsList();
    }

    reset() {
        this.setState({...this.iniitialState}, this.getProductsList)
    }

    getProductsList() {
        return apiUtils.getListingsSyncLog({
            from: (this.state.searchFromDate !== null ? this.state.searchFromDate : undefined),
            to: (this.state.searchToDate !== null ? this.state.searchToDate : undefined),
            stateSearch: this.state.stateSearch,
            str: this.state.textSearch
        })
        .then((data) => {
            this.setState({loading: false, contentLoading: false});
            if (data.syncLogItems.length > 0) {
                data.syncLogItems.forEach((row) => {
                    row.expandable = (row.details.issues.length > 0);
                    row.expanded = false;
                    row.unselectable = true
                    row.state = this.getSyncRowState(row);
                });
                this.setState({productsList: data.syncLogItems, showEmptyResult: false,
                    totalStates: data.syncLogItems.length, totalActive: data.syncLogItems.filter(item => item.state === "Active").length,
                    totalDisapproved: data.syncLogItems.filter(item => item.state === "Disapproved").length, 
                    totalPending: data.syncLogItems.filter(item => item.state === "Pending").length,
                    refreshDisabled: false, refreshLoading: false});
            }
            else {
                this.setState({showEmptyResult: true,
                    totalStates: 0, totalActive: 0, totalDisapproved: 0, totalPending: 0});
            }
        });
    }

    generateIssuesCell(row) {
        return <div>
                {row.details.issues.length} issues
            </div>;
    }

    getSyncRowState(row) {
        if (row.details.issues.filter(issue => issue.state === 'disapproved').length > 0) {
            return "Disapproved";
        }
        else if (row.details.issues.length === 0) {
            return "Active";
        }
        else if (row.details.issues.filter(issue => issue.state === 'error').length > 0) {
            return "Error";
        }
        else {
            return "Pending";
        }
    }

    generateActionStateCell(state) {
        switch (state) {
            case "warning":
                return <Box color="Y10"><Tooltip  content="Warning"><StatusWarning /></Tooltip></Box>
                break;
            default:
                return <Box color="R10"><Tooltip  content="Disapproved"><StatusAlert /></Tooltip></Box>
                break;
        }
    }

    generateStateCell(state) {
        let skin;
        switch (state) {
            case "Disapproved":
            case "Error":
                skin = "danger"
                break;
            case "Active":
                skin = "success"
                break;
            default:
                skin = "standard";
                break;
        }
        return <Badge size='small' skin={skin} type='outlined'>{state}</Badge>
    }

    expandRow(row) {
        if (row.expandable) {
            return <Table width='90%' data={row.details.issues} columns={this.issuesTableColumns}>
            </Table>
        }
    }

    getFilteredData() {
        return this.state.productsList.filter(({state, details}) => {
            if ((this.state.stateSearch !== "") && (this.state.stateSearch !== state)) {
                return false;
            }

            if ((this.state.textSearch !== "") && (!this.state.startedTyping) && ((!details.title.includes(this.state.textSearch) && (!details.targetId.includes(this.state.textSearch))))) {
                return false;
            }
            return true;
        });
    }

    doneTyping() {
        this.setState({contentLoading: true, startedTyping: false}, this.getProductsList);
    }

    newProductClicked() {
        this.props.onClick('new-product');
    }
}

export default SyncStatus