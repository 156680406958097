import React from 'react';
import {st, classes} from './GoogleStoreSelector.st.css';
import {Layout, Cell, 
    Button, Dropdown, FormField} from 'wix-style-react';

import apiUtils from '../clientCommon/apiUtils';

class GoogleStoreSelector extends React.Component {
    state = {
        currentStep: 0,
        googleDetailsLoaded: false,
        googleAccountsData: [],
        googleMerchantsAccountsList: [],
        googleMerchantsList: [],
        selectedAccountId: '',
        selectedMerchantId: '',
        googleDetailsError: false, 
        googleDetailsErrorMsg: ''
    };

    componentDidMount() {
        this.merchantAccountSelected = this.merchantAccountSelected.bind(this);
        this.merchantSelected = this.merchantSelected.bind(this);
        this.getGoogleDetails();
    }

    render() {
        return <div style={{height: '200pt'}}>
            <Layout gap={10}>
                <Cell span={12}>
                    {(this.state.googleMerchantsAccountsList.length > 1) ?
                        <FormField label="Google Account">
                            <Dropdown placeholder="Loading details..." className={classes.merchantsList} size="medium" options={this.state.googleMerchantsAccountsList} onSelect={this.merchantAccountSelected} selectedId={this.state.selectedAccountId} />
                        </FormField>
                        : null
                    }
                </Cell>
                <Cell span={12}>
                    <FormField label={(this.state.googleMerchantsAccountsList.length > 1) ? "Sub Accounts" : "Google Account"}>
                        <Dropdown placeholder="Loading details..." className={classes.merchantsList} size="medium" options={this.state.googleMerchantsList} onSelect={this.merchantSelected} selectedId={this.state.selectedMerchantId} />
                    </FormField>
                </Cell>
                <Cell span={12}>
                </Cell>
            </Layout>
        </div>
    };

    getGoogleDetails() {
        apiUtils.getGoogleMarchantAccounts()
        .then((data) => {
            if (data.hasOwnProperty("error") && (data.code === 403)) {
                this.setState({googleDetailsLoaded: true, googleDetailsError: true, googleDetailsErrorMsg: `${data.code} ${data.error}`});
            }
            else {
                let selectedAccount = data.accounts.filter(acc => acc.stores.filter(store => store.selected).length > 0)[0];
                let selectedStore = ((selectedAccount !== undefined) ? selectedAccount.stores.filter(store => store.selected)[0] : '');

                this.setState({googleAccountsData: data});
                if (data.accounts.length === 1) {
                    //in case of only one account, we will allow choosing only sub accounts
                    let stores = data.accounts[0].stores.map(merch => {return {id: merch.merchantId, value: merch.name}});
                    this.setState({googleMerchantsList: stores,
                        selectedAccountId: selectedAccount.account,//data.accounts[0].account,
                        selectedMerchantId: selectedStore.merchantId});//data.accounts[0].stores[0].merchantId});
                    this.props.accountSelected(selectedAccount.account);
                    this.props.merchantSelected(selectedStore.merchantId);
                }
                else {
                    //in case of multiple accounts, we will allow choosing accounts and their sub accounts
                    //prepare data source for accounts drop dowm
                    let accounts = data.accounts.map((acc) => {return {id: acc.account, value: acc.name}});
                    //prepare data source for account stores drop dowm (default list will be of the first account)
                    let stores = data.accounts[0].stores.map(merch => {return {id: merch.merchantId, value: merch.name}});
                    this.setState({googleMerchantsAccountsList: accounts,
                        googleMerchantsList: stores,
                        selectedAccountId: selectedAccount.account,//accounts[0].id,
                        selectedMerchantId: selectedStore.merchantId});//stores[0].id});
                    this.props.accountSelected(selectedAccount.account);
                    this.props.merchantSelected(selectedStore.merchantId);
                }
            }
        });
    }

    merchantAccountSelected(val) {
        let account = this.state.googleAccountsData.accounts.filter(acc => acc.account === val.id)[0];
        let stores = account.stores.map(merch => {return {id: merch.merchantId, value: merch.name}});
        this.setState({googleMerchantsList: stores,
            selectedMerchantId: stores[0].id,disableNextStep: false, 
            selectedAccountId: val.id.toString()});
        this.props.accountSelected(val.id.toString());
        this.props.merchantSelected(stores[0].id);
    }

    merchantSelected(val) {
        this.setState({selectedMerchantId: val.id.toString()});
        this.props.merchantSelected(val.id.toString());
    }
}

export default GoogleStoreSelector