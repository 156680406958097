import React from 'react';
import {Layout, Cell, Button,
    EmptyState, Loader, FloatingNotification} from 'wix-style-react';
import apiUtils from '../clientCommon/apiUtils';
import GoogleLogo from '../svg/google.svg';
import google_auth from '../static/google_authenticate.png';

class GoogleTokenExpired extends React.Component {
    state = {
        googleLoginState: null,
        googleLoginButtonLoading: false
    };

    componentDidMount() {
        this.startGoogleAuth = this.startGoogleAuth.bind(this);
    }

    render() {
        return <Layout cols={1} justifyItems={'center'} alignItems="center">
            <Cell>
                <EmptyState
                    image={GoogleLogo}
                    subtitle=""
                    theme="page"
                    title="">
                        <FloatingNotification showCloseButton={false} type='destructive' text='Your token has expired, please login again in order to proceed' />
                </EmptyState>
            </Cell>
            <Cell>
                <Button onClick={() => {this.setState({googleLoginButtonLoading: true});this.startGoogleAuth();}} title="Connect to Google">
                    { !this.state.googleLoginButtonLoading ? "Connect to Google" :  <Loader size="tiny" /> }  
                </Button>
            </Cell>
        </Layout>;
    }

    startGoogleAuth() {
        apiUtils.getGoogleAuthUrl()
        .then((data) => {
            window.parent.location.href = data.url;
        });
    }
}

export default GoogleTokenExpired