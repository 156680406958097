import React from 'react';
import {st, classes} from './TabsMain.st.css';

import {Button, Layout, Cell, Tabs, FloatingNotification,
    LinearProgressBar, Loader} from 'wix-style-react';
import InfoCircle from 'wix-ui-icons-common/InfoCircle';
import ConfirmSmall from 'wix-ui-icons-common/Confirm';

import apiUtils from '../clientCommon/apiUtils';
import SyncStatus from './SyncStatus';
import SyncListings from './SyncListings';
import Connections from './Connections';

class TabsMain extends React.Component {
    constructor(props) {
        super(props);
        this.syncStatusObj = React.createRef();
        this.syncListingsObj = React.createRef();
    }

    MAX_PERCENT_TO_TRACK = 96;
    MAX_MINUTES_TO_STOP_SYNC = 20;
    state = {
        activeTab: 'prod-status',
        activeRequest: -1,
        syncProgress: -1,
        syncLastUpdated: 0,
        stopSyncButtonLoading: false,
        stopSyncComplete: false,
        importDisabled: false,
        importLoading: false,
        syncBannerTitle: "Sync process in progress"
    };

    syncProgressInterval = -1;

    componentDidMount() {
        this.setActiveTab = this.setActiveTab.bind(this);
        this.renderTabContent = this.renderTabContent.bind(this);
        this.productSyncClick = this.productSyncClick.bind(this);
        this.getRequest();
    }

    componentDidUpdate(prevProps) {
        if (this.props.state !== prevProps.state) {
            this.getRequest();
            if (this.state.activeTab === 'prod-status') {
                this.syncStatusObj.current.reset();
            }
            else if (this.state.activeTab === 'new-product') {
                this.syncListingsObj.current.reset();
            }
        }
    }

    render() {
        return <div style={{minWidth: '60vw'}}>
            <Layout>
                <Cell span={12}>
                    <Tabs
                        activeId={this.state.activeTab}
                        onClick={value => this.setActiveTab(value.id)}
                        items={[
                        { id: 'prod-status', title: 'Status' },
                        { id: 'new-product', title: 'Export Listings' },
                        { id: 'connections', title: 'Connections' },
                        ]}
                    />
                </Cell>
                {this.state.activeRequest !== -1 ? 
                    <Cell>
                        <Layout>
                            <Cell>
                                {/* <Cell vertical={true}>
                                    <Text>
                                        {this.state.syncBannerTitle}
                                    </Text>
                                    {(this.state.syncProgress > this.MAX_PERCENT_TO_TRACK) ? 
                                        <Button style={{marginLeft: '10pt'}} onClick={() => {this.setState({activeRequest: -1})}} >Dismiss</Button> :
                                        null
                                    }
                                </Cell> */}
                                <FloatingNotification className={classes.suFloat}
                                    type="standard" fullWidth={true}
                                    text={
                                        <div style={{minWidth: '75vw'}}>
                                            <div style={{marginTop: '5pt'}}>
                                                {this.state.syncBannerTitle}
                                                {
                                                    //button allowing sync stop
                                                    this.state.syncLastUpdated > this.MAX_MINUTES_TO_STOP_SYNC ? 
                                                        (!this.state.stopSyncButtonLoading ?
                                                            (!this.state.stopSyncComplete ? 
                                                                <Button onClick={this.stopSync} size='tiny' style={{"marginLeft":"10pt"}}>
                                                                    Stop  
                                                                </Button> : <div style={{"marginLeft":"15pt", "display":"inline"}} ><ConfirmSmall color='green'></ConfirmSmall></div>) :
                                                            <div style={{"marginLeft":"15pt", "display":"inline"}} ><Loader size="tiny" /></div>)
                                                        : null
                                                }
                                            </div>
                                            <LinearProgressBar value={this.state.syncProgress} showProgressIndication></LinearProgressBar>
                                        </div>
                                    }
                                    prefixIcon={this.state.importLoading ? <Loader size="tiny" /> : null} 
                                    onClose={() => {this.setState({activeRequest: -1, syncBannerTitle: "Sync process in progress", syncProgress: 0})}}
                                    showCloseButton={(this.state.syncProgress > this.MAX_PERCENT_TO_TRACK)} />
                            </Cell>
                        </Layout>

                        {/* <FloatingNotification
                            type="standard" fullWidth={true} showCloseButton={false}
                            text="somethink" prefixIcon={<InfoCircle />} /> */}
                    </Cell> : null
                }
                <Cell span={12}>
                    { this.renderTabContent() }
                </Cell>
            </Layout>
        </div>
    }

    setActiveTab(val) {
        this.setState({activeTab: val});
    }

    renderTabContent() {
        switch (this.state.activeTab) {
            case 'prod-status':
                return <SyncStatus ref={this.syncStatusObj} onClick={this.productSyncClick.bind(this)} />
                break;
            case 'new-product':
                return <SyncListings ref={this.syncListingsObj} trialExpired={this.props.trialExpired} disabled={this.state.importDisabled || this.props.trialExpired} loading={this.state.importLoading} onSubmitClick={this.syncSubmitClick.bind(this)} />
                break;
            case 'connections':
                return <Connections />
                break;
            default:
                return <div>default tab...</div>
                break;
        }
    }

    productSyncClick(val) {
        this.setState({activeTab: val});
    }

    syncSubmitClick(request) {
        this.setState({activeTab: 'prod-status', activeRequest: request, importDisabled: true, importLoading: true});
        this.syncProgressInterval = setInterval(this.getRequest.bind(this), 5000);
    }

    getRequest = async () => {
        console.log(`getting request status, request: ${this.state.activeRequest}`);
        apiUtils.getListingsSyncReq(this.state.activeRequest)
        .then((data) => {
            // console.log("getting request response:");
            // console.log(data);
            if (this.state.activeRequest !== -1) {
                //in case request was just created, the ui will track its progress
                if ((data.result === 0) && (data.requests.length > 0)) {
                    if (this.state.syncProgress !== data.requests[0].percentComplete) {
                        this.setState({syncLastUpdated: this.getReqTimeSinceLastUpdate(data.requests[0].updated), syncProgress: ((data.requests[0].percentComplete > this.MAX_PERCENT_TO_TRACK) ? 100 : data.requests[0].percentComplete)}, () => {
                            this.handleSyncProgressTracking();
                        });
                    }
                    else {
                        this.setState({syncLastUpdated: this.getReqTimeSinceLastUpdate(data.requests[0].updated)}, () => {
                            this.handleSyncProgressTracking();
                        });
                    }
                }
                else {
                    if (this.syncProgressInterval !== -1) {
                        clearInterval(this.syncProgressInterval);
                    }
                    this.setState({importDisabled: false, importLoading: false});
                }
            }
            else {
                if ((data.result === 0) && (data.requests.filter(req => req.percentComplete < this.MAX_PERCENT_TO_TRACK).length > 0)) {
                    let activeRequest = data.requests.filter(req => req.percentComplete < this.MAX_PERCENT_TO_TRACK)[0];
                    this.setState({activeRequest: activeRequest.requestId, syncLastUpdated: this.getReqTimeSinceLastUpdate(data.requests[0].updated),
                        syncProgress: ((activeRequest.percentComplete > this.MAX_PERCENT_TO_TRACK) ? 100 : activeRequest.percentComplete),
                        importDisabled: true, importLoading: true});
                    this.syncProgressInterval = setInterval(this.getRequest.bind(this), 5000);
                }
            }
        });
    }

    //get time since request was last updated
    getReqTimeSinceLastUpdate = (date1) => {
        return Math.ceil(Math.abs((new Date()) - (new Date(date1))) / (1000 * 60));
    }

    handleSyncProgressTracking = () => {
        if (this.state.syncProgress > this.MAX_PERCENT_TO_TRACK) {
            this.setState({syncBannerTitle: "Sync process completed. You may have errors or pending status from Google, view the results in the list at the Status tab."});
            this.setState({importDisabled: false, importLoading: false});
            // console.log(`clearInterval this.syncProgressInterval: ${this.syncProgressInterval}`)
            clearInterval(this.syncProgressInterval);
        }
    }

    stopSync = () => {
        this.setState({stopSyncButtonLoading: true});
        apiUtils.stopSync()
        .then((data) => {
            if (data.result === 0) {
                this.setState({stopSyncButtonLoading: false, stopSyncComplete: true,
                    syncBannerTitle: "Sync stopped successfully", importLoading: false, syncProgress: 100});
                clearInterval(this.syncProgressInterval)
            }
        });
    }
}

export default TabsMain