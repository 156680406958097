export default {
    getGoogleAuthUrl: () => {
        return fetch('/api/google/auth-start')
        .then(res => res.json());
    },

    getWizardState: () => {
        //for debug
        //wizard complete
        // return (new Promise((resolve) => {resolve();})).then(() => {return {wizard: {step: 999, state: 0}}});
        //select account
        // return (new Promise((resolve) => {resolve();})).then(() => {return {wizard: {step: 2, state: 0}}});
        return fetch('/api/app/wizardState')
        .then(res => res.json());
    },

    getGoogleUser: () => {
        //for debug
        // return (new Promise((resolve) => {resolve();})).then(() => {return {"name": "Assaf Leibovich","email": "assaf.leibovich@gmail.com"}});
        return fetch('/api/google/user')
        .then(res => res.json());
    },

    getGoogleMarchantAccounts: () => {
        //for debug
        // return (new Promise((resolve) => {resolve();})).then(() => {
        //     return {"accounts": [
        //             {
        //                 "account": "582575226",
        //                 "name": "shopupz store",
        //                 "stores": [
        //                     {
        //                         "merchantId": "565491908",
        //                         "name": "shopupz store"
        //                     },
        //                     {
        //                         "merchantId": "582532975",
        //                         "name": "Assaf store"
        //                     },
        //                     {
        //                         "merchantId": "582534067",
        //                         "name": "Shopify app"
        //                     }
        //                 ]
        //             },
        //             {
        //                 "account": "585207519",
        //                 "name": "Genemails",
        //                 "stores": [{
        //                     "merchantId": "585207519",
        //                     "name": "Genemails"
        //                 }]
        //             }
        //         ]
        //     }
        // });
        return fetch('/api/google/accounts')
        .then(res => res.json());
    },

    setGoogleMarchant: (accountId, merchantId) => {
        //for debug
        // return new Promise()
        let requestOptions = {
            method: 'PUT'
        };
        return fetch(`/api/google/merchant/${accountId}/${merchantId}`, requestOptions)
        .then(res => res.json());
    },

    getListingsSyncLog: (postData) => {
        //for debug
        // return (new Promise((resolve) => {
        //     resolve();}))
        //     .then(() => {
        //         return {
        //             syncLogItems: [
        //                 {
        //                     "source": "Wix",
        //                     "target": "Google",
        //                     "details": {
        //                         "targetId": "online:en:US:364215376135191",
        //                         "title": "I'm a product",
        //                         "sourceUrl": "https://assaf735.wixsite.com/website/product-page/i-m-a-product",
        //                         "targetUrl": "https://merchants.google.com/mc/items/details?a=582532975&offerId=364215376135191&language=en&channel=0&country=US",
        //                         "lastUpdateDate": "2022-07-05T21:36:37Z",
        //                         "issues": [
        //                             {
        //                                 "name": "Limited performance due to missing ISBN or GTIN",
        //                                 "desc": "Add your product's globally valid ISBN or GTIN",
        //                                 "learnMore": "https://support.google.com/merchants/answer/9542185",
        //                                 "state": "demoted"
        //                             },
        //                             {
        //                                 "name": "Pending initial review",
        //                                 "desc": "Please wait up to 3 business days for the review to be completed",
        //                                 "learnMore": "https://support.google.com/merchants/answer/2948694",
        //                                 "state": "disapproved"
        //                             },
        //                             {
        //                                 "name": "Missing value [availability]",
        //                                 "desc": "Add this attribute to your product data",
        //                                 "learnMore": "https://support.google.com/merchants/answer/7052112",
        //                                 "state": "disapproved"
        //                             },
        //                             {
        //                                 "name": "Missing value [shipping]",
        //                                 "desc": "Add shipping costs for your product",
        //                                 "learnMore": "https://support.google.com/merchants/answer/6239383",
        //                                 "state": "disapproved"
        //                             },
        //                             {
        //                                 "name": "Missing value [tax]",
        //                                 "desc": "Add tax information in your Merchant Center settings or in your product data",
        //                                 "learnMore": "https://support.google.com/merchants/answer/7052209",
        //                                 "state": "disapproved"
        //                             }
        //                         ]
        //                     },
        //                     "created": "2022-07-19T15:54:15.000Z",
        //                     "fail": 0
        //                 },
        //                 {
        //                     "source": "Wix",
        //                     "target": "Google",
        //                     "details": {
        //                         "targetId": "online:en:US:364215376135191",
        //                         "title": "I'm a product",
        //                         "sourceUrl": "https://assaf735.wixsite.com/website/product-page/i-m-a-product",
        //                         "targetUrl": "https://merchants.google.com/mc/items/details?a=582532975&offerId=364215376135191&language=en&channel=0&country=US",
        //                         "lastUpdateDate": "2022-07-05T21:36:37Z",
        //                         "issues": []
        //                     },
        //                     "created": "2022-07-18T15:54:15.000Z",
        //                     "fail": 0
        //                 }
        //             ]
        //         }});
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(postData)
        };
        return fetch('/api/app/listingsSyncLog', requestOptions)
        .then(res => res.json());
    },

    getWixCollections: (postData) => {
        //for debug
        // return (new Promise((resolve) => {resolve();})).then(() => {return {collections: [{id: 1, name: "coll1", numberOfProducts: 14}]}});
        return fetch('/api/wix/collections')
        .then(res => res.json());
    },

    getWixCollectionVisibles: (coll) => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return { "count": 1}});
        return fetch('/api/wix/collections/visible/' + coll)
        .then(res => res.json());
    },

    getWixLocales: (postData) => {
        //for debug
        // return (new Promise((resolve) => {resolve();})).then(() => {return {
        //     "currency": "ILS",
        //     "locales": [
        //         {
        //             "lang": "en",
        //             "country": "US"
        //         },
        //         {
        //             "lang": "fr",
        //             "country": "CA"
        //         }
        //     ]
        // }});
        return fetch('/api/wix/locales')
        .then(res => res.json());
    },

    getGoogleShippingSettings: () => {
        //for debug
        // return (new Promise((resolve) => {resolve();})).then(() => {return [{name: "setting1"}]});
        return fetch('/api/google/shippingsettings')
        .then(res => res.json());
    },

    submitSync: (postData) => {
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(postData)
        };
        return fetch('/api/app/syncProds', requestOptions)
        .then(res => res.json());
    },

    getListingsSyncReq: (req) => {
        //for debug
        // return (new Promise((resolve) => {resolve();})).then(() => {return {
        //     "result": 0,
        //     "requests": [
        //         {
        //             "requestId": 1,
        //             "siteId": 2858,
        //             "percentComplete": 100,
        //             "created": "2021-08-18T18:08:47.570Z",
        //             "updated": "2021-08-18T18:08:47.570Z"
        //         }
        //     ]
        // }});
        return fetch('/api/app/listingsSync/' + req)
        .then(res => res.json());
    },

    getTrialDays: () => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return [{remainingDays: 9}]});
        return fetch('/api/app/trialState')
        .then(res => res.json());
    },

    getWixChargeUrl: () => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return { "url": "https://www.wix.com/apps/upgrade/ord"}});
        return fetch('/api/wix/charge')
        .then(res => res.json());
    },

    getChannels: () => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return { "channels": [{"name": "Google","state":"1"}]}});
        return fetch('/api/app/channels')
        .then(res => res.json());
    },

    removeChannel: () => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return { "status": "0"}});
        const requestOptions = {
            method: 'DELETE'
        };
        return fetch('/api/google/store', requestOptions)
        .then(res => res.json());
    },

    stopSync: () => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return { "result": 0}});
        return fetch('/api/app/stopSyncRequest')
        .then(res => res.json());
    },

    getProductOptions: () => {
        //for debug
        // return (new Promise((resolve) => {setTimeout(() => {setTimeout(() => {resolve();}, 1000) }, 1000) })).then(() => {return { "result": [
        //     {
        //         "name": "Color",
        //         "value": "Color"
        //     },
        //     {
        //         "name": "TestOpt",
        //         "value": "TestOpt"
        //     }
        // ]}});
        return fetch('/api/wix/options')
        .then(res => res.json());
    },

    getInstance: () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
          });
        return (params.instance !== null ? params.instance : ''); 
    }
}