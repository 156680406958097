module.exports = {
    WizardStates: {
        "GoogleLogin_AccessDenied": "-1",
        "GoogleLogin_ExpiredRefreshToken": "-2",
        "GoogleLogin_MissingScopes": "-3",
        "GoogleLogin_LoginFailed": "-99"
    },

    ChannelStates: {
        "Connected": "1"
    }
}
